import styled from "styled-components"

import { IconButton, Tooltip } from "@material-ui/core"

import { AdjustableHomeMap } from "src/components/Homes/HomeAddress/AdjustableHomeMap"
import {
  formatPostalAddress,
  formatStreetname,
} from "src/components/Homes/HomeDetails/Overview/addressUtils"
import { IAddress, ILocation } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { ErrorIcon } from "src/ui/ErrorIcon/ErrorIcon"
import EditIcon from "src/ui/icons/edit.svg"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function AddressCard({
  location,
  address,
  editable,
  onEdit,
}: {
  location?: ILocation
  address?: IAddress
  editable?: boolean
  onEdit: () => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MCardInteractive>
      <MapCardGrid>
        <CardHeader>
          <MText variant="heading3">{t(langKeys.address)}</MText>

          <Tooltip
            title={editable ? "" : `${t(langKeys.not_enough_permissions)}`}
          >
            <span>
              <IconButton size="small" onClick={onEdit} disabled={!editable}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardHeader>

        <AdjustableHomeMap
          showTooltip={false}
          showAdjustButton={false}
          height="200px"
          markerWidth="30px"
          location={{
            latitude: location?.latitude || 0,
            longitude: location?.longitude || 0,
          }}
        />

        <div>
          <AdressWarning address={address} location={location} />

          {address && (
            <>
              <MText variant="body">{formatStreetname(address)}</MText>
              <MText variant="bodyS" color="secondary">
                {formatPostalAddress(address)}
              </MText>
            </>
          )}
        </div>
      </MapCardGrid>
    </MCardInteractive>
  )
}

export function AdressWarning({
  address,
  location,
}: {
  address?: IAddress
  location?: ILocation
}) {
  const { t, langKeys } = useTranslate()

  if (address && location) {
    return <></>
  }

  return (
    <WarningBox>
      {!address && (
        <WarningBoxRow>
          <StyledErrorIcon state="warning" />
          <MText variant="body">
            {t(langKeys.home_settings_missing_address_info)}
          </MText>
        </WarningBoxRow>
      )}
      {!location && (
        <WarningBoxRow>
          <StyledErrorIcon state="warning" />
          <MText variant="body">
            {t(langKeys.home_settings_missing_location_info)}
          </MText>
        </WarningBoxRow>
      )}
    </WarningBox>
  )
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MapCardGrid = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
`

const WarningBox = styled.div`
  display: grid;
  grid-gap: ${spacing.S};
`

const WarningBoxRow = styled.div`
  display: flex;
`

const StyledErrorIcon = styled(ErrorIcon)`
  margin-right: ${spacing.XS};
`
